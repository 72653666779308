import { lazy, ReactElement, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoadingSpinner } from '../components/shared/LoadingSpinner'
const EventDashboard = lazy(() => import('../pages/events/EventDashboard'))
const EventAdmin = lazy(() => import('../pages/events/EventAdmin'))
const EventKiosk = lazy(() => import('../pages/events/EventKiosk'))
const EventReports = lazy(() => import('../pages/events/EventReports'))
const EventIntegrations = lazy(() => import('../pages/events/EventIntegrations'))
const EventBadges = lazy(() => import('../pages/events/EventBadges'))
const EventPrinters = lazy(() => import('../pages/events/EventPrinters'))
const EventRegTypes = lazy(() => import('../pages/events/EventRegTypes'))

const ZapierIntegration = lazy(() => import('../pages/events/integrations/ZapierIntegration'))

export const EventsRoutes = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <Routes>
        <Route path="/*" element={<Navigate replace to="/events/dashboard" />} />
        <Route path="/dashboard" element={<EventDashboard />} />
        <Route path="/admin" element={<EventAdmin />} />
        <Route path="/kiosk" element={<EventKiosk />} />
        <Route path="/reports" element={<EventReports />} />
        <Route path="/integrations" element={<EventIntegrations />} />
        <Route path="/badges" element={<EventBadges />} />
        <Route path="/reg-types" element={<EventRegTypes />} />
        <Route path="/printers" element={<EventPrinters />} />
        <Route path="/zapier-integration" element={<ZapierIntegration />} />
      </Routes>
    </Suspense>
  )
}
