import { FC, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { PublicRoutes } from './PublicRoutes'
import { PrivateRoutes } from './PrivateRoutes'
import '../App.css'
import { LoadingSpinner } from '../components/shared/LoadingSpinner'
import { AdminRoutes } from '../adminModule/router/AdminRoutes'
import { RouteGuard } from './RouteGuard'
import { AccountPermissions, AuthRoles } from '../constants/common'
import { EventsRoutes } from './EventsRoutes'
import { useProfileContext } from '../contexts/profileContext'

const Dashboard = lazy(() => import('../pages/Dashboard'))
const DashboardEvent = lazy(() => import('../pages/DashboardEvent'))
const CreateJob = lazy(() => import('../pages/CreateJob'))
const CreateTemplate = lazy(() => import('../pages/CreateTemplate'))
const JobListing = lazy(() => import('../pages/JobListing'))
const JobDetail = lazy(() => import('../pages/JobDetail'))
const RecordSetListing = lazy(() => import('../pages/RecordSetListing'))
const CreateFieldSet = lazy(() => import('../pages/CreateFieldSet'))
const TemplateLibrary = lazy(() => import('../pages/TemplateLibrary'))
const UserProfile = lazy(() => import('../pages/UserProfile'))
const UserDetail = lazy(() => import('../pages/UserDetail'))
const UserLists = lazy(() => import('../pages/UserLists'))
const UploadDesign = lazy(() => import('../pages/UploadDesign'))
const ManageSet = lazy(() => import('../pages/ManageSet'))
const AddRecord = lazy(() => import('../pages/AddRecord'))
const ImportRecord = lazy(() => import('../pages/ImportRecord'))
const ManageAddress = lazy(() => import('../pages/ManageAddress'))
const CreateAddress = lazy(() => import('../pages/CreateAddress'))
const AddUser = lazy(() => import('../pages/AddUser'))
const AccountBilling = lazy(() => import('../pages/AccountBilling'))
const ViewTransactionDetails = lazy(() => import('../pages/ViewTransactionDetails'))
const TransactionDetail = lazy(() => import('../pages/TransactionDetail'))

const Events = lazy(() => import('../pages/Events'))
const Kiosk = lazy(() => import('../pages/Kiosk'))
const CreateEvent = lazy(() => import('../pages/CreateEvent'))
const Unauthorized = lazy(() => import('../components/layout/Auth/Unauthorized'))
const HowToUse = lazy(() => import('../pages/HowToUse'))

export const AppRouter: FC = () => {
  const { profile } = useProfileContext()
  return (
    <Router>
      <Suspense fallback={<LoadingSpinner isLoading={true} label={'Loading...'} />}>
        <Routes>
          <Route path="/" element={<PublicRoutes />} index />
          <Route path="/" element={<PrivateRoutes />}>
            {profile.AuthRoles.includes(AuthRoles.Onsite) && <Route path="/home" index element={<DashboardEvent />} />}
            {!profile.AuthRoles.includes(AuthRoles.Onsite) && <Route path="/home" index element={<Dashboard />} />}
            <Route
              path="/create-job"
              element={
                <RouteGuard requiredPermission={AccountPermissions.CanCreatePrintJobs}>
                  <CreateJob />
                </RouteGuard>
              }
            />
            <Route
              path="/create-template"
              element={
                <RouteGuard requiredPermission={AccountPermissions.CanCreateTemplates}>
                  <CreateTemplate />
                </RouteGuard>
              }
            />

            <Route path="/job-listing" element={<JobListing />} />
            <Route path="/job-detail" element={<JobDetail />} />
            <Route path="/lists" element={<RecordSetListing />} />
            <Route
              path="/create-list"
              element={
                <RouteGuard requiredPermission={AccountPermissions.CanCreateLists}>
                  <CreateFieldSet />
                </RouteGuard>
              }
            />
            <Route path="/template-library" element={<TemplateLibrary />} />
            <Route path="/add-user" element={<AddUser />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/user-detail" element={<UserDetail />} />
            <Route path="/user-list" element={<UserLists />} />
            <Route path="/upload-design" element={<UploadDesign />} />
            <Route path="/manage-list" element={<ManageSet />} />
            <Route path="/add-record" element={<AddRecord />} />
            <Route path="/import-record" element={<ImportRecord />} />
            <Route path="/manage-address" element={<ManageAddress />} />
            <Route path="/events" element={<Events />} />
            <Route path="/kiosk" element={<Kiosk />} />
            <Route path="/create-event" element={<CreateEvent />} />
            <Route path="/how-to-use" element={<HowToUse />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route
              path="/create-address"
              element={
                <RouteGuard requiredPermission={AccountPermissions.CanCreateAddresses}>
                  <CreateAddress />
                </RouteGuard>
              }
            />
            <Route path="/account-billing" element={<AccountBilling />} />
            <Route path="/view-transactions-details" element={<ViewTransactionDetails />} />
            <Route path="/detail-transactions" element={<TransactionDetail />} />
          </Route>
          <Route path="/*" element={<PublicRoutes />} />
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route path="/events/*" element={<EventsRoutes />} />
        </Routes>
      </Suspense>
    </Router>
  )
}
