import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { ProfileProvider } from './contexts/profileContext'

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?? ''
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ?? ''

// get the mode parameter from the querystring
const urlParams = new URLSearchParams(window.location.search)
const mode = urlParams.get('mode')
let loginHint = ''
let signupHint = ''
const redirectUri = window.location.origin

if (mode === '' || mode === 'undefined') {
  loginHint = 'login'
  signupHint = ''
} else if (mode === 'signup') {
  loginHint = ''
  signupHint = 'signup'
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: AUTH0_AUDIENCE,
        loginHint: loginHint,
        screen_hint: signupHint,
      }}
    >
      <ProfileProvider>
        <App />
      </ProfileProvider>
    </Auth0Provider>
  </React.StrictMode>
)
