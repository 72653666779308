import { ReactElement, ReactNode, useState } from 'react'
import { Button as AntButton, Tooltip } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'

export enum SiteButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Gradient = 'gradient',
}

interface Props {
  label: string
  id: string
  variant?: SiteButtonVariant
  disabled?: boolean
  type?: 'button' | 'submit'
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void
  style?: React.CSSProperties
  size?: SizeType
  icon?: ReactNode
  singleClick?: boolean
  tooltipMessage?: string
}

export function SiteButton({
  label,
  id,
  variant = SiteButtonVariant.Primary,
  type = 'button',
  disabled = false,
  onClick,
  style,
  size,
  icon,
  singleClick = false,
  tooltipMessage,
}: Props): ReactElement {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleClick = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (singleClick && isButtonDisabled) return

    if (singleClick) {
      setIsButtonDisabled(true)
      setTimeout(() => setIsButtonDisabled(false), 1000) // Re-enable after 1 second
    }

    if (onClick) {
      onClick(e)
    }
  }

  const getButtonClasses = (v: SiteButtonVariant): string => {
    switch (v) {
      case SiteButtonVariant.Primary:
        return 'text-white hover:!text-white focus:!text-white bg-credsPrimaryBlue-100 hover:!bg-PrimaryDarkBlue-100 m-3 px-4 inline-flex items-center'
      case SiteButtonVariant.Secondary:
        return 'rounded-md border border-credsPrimaryBlue-100 bg-PrimaryBgWhite-100 shadow-sm hover:!bg-PrimaryDarkBlue-100 hover:!text-white m-3 px-4 inline-flex items-center'
      case SiteButtonVariant.Gradient:
        return 'text-white hover:!text-white focus:!text-white !bg-gradient-to-r !from-blue-500 !to-purple-600 !border-0 !shadow-lg hover:!from-blue-800 hover:!to-purple-800'
      default:
        return ''
    }
  }

  const buttonClasses = getButtonClasses(variant)

  const button = (
    <AntButton
      icon={icon}
      size={size}
      style={style}
      className={buttonClasses}
      onClick={handleClick}
      id={id}
      disabled={disabled || isButtonDisabled}
      htmlType={type}
    >
      {label}
    </AntButton>
  )
  return tooltipMessage ? <Tooltip title={tooltipMessage}>{button}</Tooltip> : button
}
