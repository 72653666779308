import { PagedResult } from '../models/Dtos/pagedResult'
import { RecordDataDto, RecordDataBatchDto } from '../models/Dtos/RecordDataDto'
import { RecordSetDto } from '../models/Dtos/RecordSetDto'
import { FieldSet } from '../models/manager/fieldset'
import { RecordAuxRequest, Record } from '../models/manager/record'
import { RecordSet } from '../models/manager/recordset'
import { apiRequest } from '../utils/axios'

// record-set functions
export async function getRecordSetByTemplateId(templateId: number, token: string): Promise<RecordSet> {
  return apiRequest('GET', `/recordSetDataByTemplateId/${templateId}`, token)
}

export async function getRecordSetDataByRecordSetId(recordSetId: number, token: string): Promise<RecordSetDto> {
  return apiRequest('GET', `/recordSetDataByRecordSetId/${recordSetId}`, token)
}

export async function fetchRecordSets(accountId: number, token: string): Promise<RecordSet[]> {
  return apiRequest('GET', `/recordSets/${accountId}`, token)
}

export async function fetchRecord(recordId: number, token: string): Promise<Record> {
  return apiRequest('GET', `/record/${recordId}`, token)
}

export async function saveRecordSet(recordSetData: RecordSetDto, token: string): Promise<RecordSetDto> {
  return apiRequest('POST', '/recordSet', token, recordSetData)
}

export async function updateRecordSet(recordSetData: RecordSetDto, token: string): Promise<RecordSetDto> {
  return apiRequest('PUT', '/recordSet', token, recordSetData)
}

export async function deleteRecordSet(recordSetId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/recordSet/${recordSetId}`, token)
}

// record functions
export async function getRecordsByRecordSetId(
  recordSetId: number,
  token: string,
  page: number | null = null,
  take: number | null = null,
  search: string | null = null
): Promise<PagedResult<RecordAuxRequest[]>> {
  let url = `/records/${recordSetId}`
  if (page && take) {
    url += `?page=${page}&take=${take}`
  }
  if (search && search.length > 0) {
    if (page && take) {
      url += `&search=${search}`
    } else {
      url += `?search=${search}`
    }
  }
  return apiRequest('GET', url, token)
}

export async function countRecords(recordSetId: number, token: string): Promise<number> {
  return apiRequest('GET', `/recordCount/${recordSetId}`, token)
}

export async function countListsForAccount(accountId: number, token: string): Promise<number> {
  return apiRequest('GET', `/recordSetCountForAccount/${accountId}`, token)
}

export async function checkedInRecordsForEvent(eventId: number, token: string): Promise<Record[]> {
  return apiRequest('GET', `/recordsCheckedInByEventId/${eventId}`, token)
}

export async function saveRecordData(recordData: RecordDataDto, token: string): Promise<RecordDataDto> {
  return apiRequest('POST', '/recordData', token, recordData)
}

export async function saveRecordDataBatch(recordData: RecordDataBatchDto, token: string): Promise<RecordDataDto> {
  return apiRequest('POST', '/recordDataBatch', token, recordData)
}

export async function updateRecordData(recordData: RecordDataDto, token: string): Promise<RecordDataDto> {
  return apiRequest('PUT', '/recordData', token, recordData)
}

export async function deleteRecord(recordId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/recordData/${recordId}`, token)
}

//field-set functions
export async function fetchFieldSet(recordSetId: number, token: string): Promise<FieldSet[]> {
  return apiRequest('GET', `/fieldSet/${recordSetId}`, token)
}

export async function saveFieldSet(fieldSetData: FieldSet, token: string): Promise<number> {
  return apiRequest('POST', '/fieldSet', token, fieldSetData)
}

export async function updateFieldSet(fieldSetData: FieldSet, token: string): Promise<void> {
  return apiRequest('PUT', '/fieldSet', token, fieldSetData)
}

export async function deleteFieldSet(fieldSetId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/fieldSet/${fieldSetId}`, token)
}
